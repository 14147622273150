.homeNavbar .menuGrid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	transform: translate(0, -30%);
	justify-content: center;
}

.menuGridItem {
	display: flex;
	flex: 0 0 190px;
	justify-content: flex-start;
	margin: 4px;
}

.navVisible .menuGridItem {
	flex: unset;
}

.homeNavbar .actionButton {
	flex: 1 0 100%;
}

.newTag {
	color: yellow;
}

.navbarLines {
	height: 40px;
	/* padding: 0 80px; */
	/* background-image: url("../../images/BMWMCertLines.svg"); */
	background-size: cover;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	position: relative;
	max-width: 800px;
}

.homeNavbar {
	height: 100%;
	width: 100%;
	z-index: 9;
	-webkit-transform: translate3d(0, 0, 0);
}

/* .navbarLines::before {
	content: "";
	background: #000;
	width: calc(100% - 120px);
	height: 100%;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%) skew(-30deg);
	display: block;
} */

.navbarMenu {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: absolute;
}

.navbarMenu .menuGridItem {
	margin: 0;
	height: 40px;
}

.navbarMenu .MuiTypography-body1 {
	color: var(--onprimary);
}

.homeNavbar .menuGrid,
.navbarMenu .menuGrid {
	position: relative;
	height: 100%;
}

.homeNavbar .actionButtonIconContainer {
	height: 36px;
	width: 36px;
	margin-right: 10px;
}

.homeNavbar .actionButtonIcon {
	height: 36px;
	width: 36px;
}

.homeNavbar .actionButtonIcon svg {
	height: 22px;
	width: 22px;
	fill: var(--onprimary);
}

.homeNavbar .actionButtonStatus {
	height: 42px;
	width: 40px;
}

.homeNavbar .actionButtonStatus svg {
	height: 22px;
	width: 22px;
	fill: var(--onprimary);
}

.homeNavbar .actionButtonStatus svg path {
	fill: var(--onsecondary);
}

.homeNavbar .actionButton .MuiTypography-root {
	font-size: 0.8rem;
}

#btnMenu {
	z-index: 99;
}

#btnMenu .MuiTypography-root {
	color: var(--onprimary);
}

#btnMenu .actionButtonIconContainer {
	height: 0;
}

#btnMenu .actionButtonIconContainer,
#btnMenu .actionButtonIcon,
#btnMenu svg {
	width: 50px;
	fill: var(--onprimary);
}

#btnHome svg {
	height: 34px;
	width: 34px;
}

#btnTrainers .actionButtonIconContainer,
#btnTrainers .actionButtonIcon,
#btnTrainers svg {
	width: 50px;
}

.navbarLinesHolding {
	flex: 1 0 auto;
	align-items: flex-end;
	padding-bottom: 2rem;
}

/* @media screen and (min-width: 1280px) {
	.homeNavbar .actionButton .MuiTypography-body1 {
		font-size: 1.2rem;
	}
} */

@media screen and (min-width: 1279px) {
	.navbarMenu {
		display: none;
	}
}

@media screen and (min-width: 768px) and (min-height: 767px) {
	.navbarLinesHolding {
		/* margin: auto 0; */
		height: 5rem;
	}

	.navbarLinesHolding::before {
		width: 400px !important;
	}
}

@media screen and (max-width: 1279px) {
	.homeNavbar .menuGrid {
		position: absolute;
		top: 40px;
		left: 50%;
		transform: translateX(-50%);
		background-color: var(--primary);
		flex-direction: column;
		z-index: -1;
		padding: 5px 20px;
		height: auto;
		width: 300px;
		box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 40%);
	}

	.navbarLines::before {
		width: 200px;
	}

	#btnSignOut {
		order: 99;
	}

	.homeNavbar.navHidden {
		display: none;
	}

	.homeNavbar.navVisible {
		display: block;
	}
}
