.App {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	text-align: center;
	background: var(--primary);

	color: var(--onprimary);
	padding-bottom: 0;
}
.holdingSignIn {
	flex: 1 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 80px;
	padding-bottom: 2rem;
	margin-bottom: 10px;
	position: relative;
}

.loadingSpinner {
	flex: 1 0 auto;
	align-items: center;
	justify-content: center;
}

/* HEADER */

#appHeader {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	z-index: 10;
}

#appHeader.appHeaderHolding {
	flex-grow: 1;
}
#appHeaderLogos {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#logoLeft,
#logoRight {
	margin-right: 40px;
}

#logoLeft img {
	height: 120px;
	transform: translate(-50%, -50%) scale(2.2);
}

.appHeaderHolding #logoLeft img {
	transform: translate(-50%, -50%) scale(6);
}

#logoRight img {
	height: 60px;
}
#btnHeader {
	display: block;
	text-align: center;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	background-color: var(--secondary);
	border: none;
}

#btnHeader .MuiButton-startIcon {
	margin: 0;
}

#btnHeader svg {
	fill: var(--onsecondary);
	height: 2.5rem;
}

#btnHeader .MuiTypography-body1 {
	font-size: 2rem;
	color: var(--onsecondary);
}

/* BODY */

#appContent {
	flex: 1 1 auto;
	position: relative;
	display: flex;
	overflow: hidden;
	padding: 0 10px;
}

.appPage {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex: 1 1 auto;
	overflow-x: hidden;
	overflow-y: auto;
}

.appPageContent {
	background-color: var(--fill);
	padding: 15px 15px;
	flex: 1 0 auto;
	width: 100%;
	max-width: 1000px;
	text-align: center;
	padding-top: 4rem;
	border-radius: 8rem 8rem 0 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.appPageContent.firstVideo {
	background-color: unset;
	padding: 0 !important;
	justify-content: center;
}

.appPageContent.MuiDialogContent-root {
	border-radius: 0;
	border: 4px solid var(--primary);
}

.appPageContent .MuiTypography-root {
	color: var(--onfill);
}
.appPageContent .MuiTypography-root.actionButtonTitle {
	color: var(--secondary);
}

.appPageContent .MuiTypography-root.highlightText {
	color: var(--secondary);
}

.appPageContent h2,
.appPageContent h4 {
	font-weight: bold;
	margin: 0;
	padding-bottom: 20px;
}

.pageTitle {
	font-size: 2.6rem !important;
	padding-bottom: 35px !important;
	font-family: MINISerifHeadline !important;
}
.pageSubTitle {
	font-family: MINISerifHeadline !important;
}

.appPageContent .MuiCheckbox-root svg {
	fill: var(--secondary);
}

.appPageContent ul {
	width: max-content;
	margin-left: auto;
	margin-right: auto;
	color: var(--onfill);
}

.appPageContent .boldButton {
	cursor: pointer;
	font-weight: bold;
	color: var(--secondary);
}

/* HOLDING PAGE */

#holding {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

#holdingTitle {
	flex: 0 0 40%;
	display: flex;
	align-items: center;
	justify-content: center;
}

#holdingTitle h2 {
	max-width: 100%;
}

#holding img {
	height: 50vh;
	max-width: 90vw;
}

/* FOOTER */
footer {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 5px 10px;
}

.contactLink {
	text-align: left;
	line-height: 1.4rem;
}

.contactLink a {
	color: var(--onprimary);
}

.privacyPolicyLink {
	text-decoration: underline;
	cursor: pointer;
	z-index: 99;
}

.privacyPolicy .MuiButton-label {
	color: #000;
}

.privacyPolicy h4 {
	margin-bottom: 16px;
	font-size: 1rem;
}

.privacyPolicy li {
	font-size: 0.9rem;
}

/* GENERAL ELEMENT STYLES */

a,
a:visited {
	color: var(--onprimary);
}

.appPage a,
.appPage a:visited {
	color: var(--onfill);
}

li {
	text-align: left;
}

.MuiTypography-h2 {
	font-size: 2rem;
}
.MuiTypography-h4 {
	font-size: 1.4rem;
}
.MuiTypography-body1 {
	font-size: 1rem;
}

.MuiFormLabel-root {
	font-size: 1.4rem;
}

.PrivateNotchedOutline-legendLabelled-7 {
	font-size: 0.85rem;
}

.MuiFormControl-root {
	margin-bottom: 16px;
	color: var(--onfill);
}

.MuiButton-outlined {
	border-color: var(--onfill);
}
.MuiButton-outlined.Mui-disabled {
	color: rgba(255, 255, 255, 0.3);
	border-color: rgba(255, 255, 255, 0.3);
}

.MuiInputLabel-root,
.MuiInput-root,
.MuiInputBase-root,
.MuiFormControl-root .Mui-focused,
.MuiButton-root:not(.Mui-disabled),
.MuiFormHelperText-root {
	color: var(--onfill);
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline {
	border-color: var(--onfill);
}

.MuiFormControl-root .MuiInput-underline:before,
.MuiFormControl-root .MuiInput-underline:after {
	border-bottom-color: var(--onfill) !important;
	height: 2px;
	width: 100%;
}

.MuiIconButton-label {
	color: var(--onsecondary);
}

/* .MuiBackdrop-root {
	background-color: rgba(255, 255, 255, 0.5);
} */

@media screen and (max-width: 767px) {
	#logoLeft img {
		height: 100px;
	}
	.appHeaderHolding #logoLeft img {
		transform: translate(-50%, -50%) scale(4);
	}
	#logoSpacer {
		padding-left: unset;
	}

	#logoRight img {
		height: 40px;
	}

	#appContent {
		padding: 0 5px;
	}
}

@media screen and (max-width: 350px) {
	.appPageContent {
		border-radius: 4rem 4rem 0 0;
	}

	.navbarLines {
		transform: translateY(50px);
	}
}

@media screen and (max-height: 768px) and (min-aspect-ratio: 1/1) {
	#appHeaderLogos {
		margin-bottom: 10px;
	}

	#logoLeft img {
		height: 100px;
	}
	.appHeaderHolding #logoLeft img {
		transform: translate(-50%, -50%) scale(3);
	}
	#logoSpacer {
		padding-left: unset;
	}

	#logoRight img {
		height: 40px;
	}

	.appPageContent.firstVideo {
		height: 100%;
	}

	.appPageContent.firstVideo #homeVideoContainer {
		width: 100%;
	}
}

@media screen and (max-height: 767px) and (min-aspect-ratio: 1/1) {
	.appPageContent.firstVideo #homeVideoContainer {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.appPageContent.firstVideo #homeVideoContainer video {
		height: 100%;
	}
}
