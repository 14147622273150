.actionButton {
	cursor: pointer;
	display: flex;
	align-items: center;
	text-align: left;
	border-bottom: 2px solid transparent;
}

.actionButton .MuiTypography-body1 {
	font-size: 1rem;
}

.actionButton.disabled {
	cursor: auto;
	pointer-events: none;
	opacity: 0.4;
}

.actionButtonImg {
	height: 60px;
	width: 80px;
	margin-right: 10px;
	border-right: 1px solid white;
	background-size: contain;
	background-position: left;
	flex-shrink: 0;
}

.actionButton:hover {
	border-bottom-color: #fff;
}

.actionButtonIconContainer {
	width: 60px;
	height: 60px;
	margin-right: 10px;
	flex-shrink: 0;
	display: flex;
	background-size: contain;
	background-position: left;

	justify-content: flex-start;
	align-items: center;
	position: relative;
	background-color: var(--secondary);
	border-radius: 500px;
}

.actionButtonIcon {
	height: 54px;
	width: 59px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.actionButtonIconClickable {
	cursor: pointer;
}

.actionButtonStatus {
	position: absolute;
	display: flex;
	height: 65px;
	width: 70px;
	justify-content: flex-end;
	align-items: flex-end;
}

.actionButtonIcon svg {
	width: 30px;
	height: 30px;
	fill: var(--onsecondary);
}
.actionButtonStatus svg {
	width: 30px;
	height: 30px;
}
