.signInDialog * {
	color: var(--onsecondary) !important;
}

.signInDialog .MuiDialog-paper {
	background-color: var(--secondary);
	/* FOR CIRCLE */
	border-radius: 50%;
	width: 350px;
	height: 350px;

	justify-content: center;
	text-align: center;
	padding: 25px;
}

.signInDialog .MuiButton-root {
	font-weight: bold;
}

.signInDialog .MuiDialogActions-root {
	justify-content: space-around;
}

.signInDialog .MuiDialogContent-root {
	display: flex;
	flex-direction: column;
	/* FOR CIRCLE */
	flex: unset;
}

.signInDialog .MuiDialogContent-root .MuiTypography-root {
	text-align: center;
}

.signInDialog .MuiFormControl-root .MuiInput-underline:before,
.signInDialog .MuiFormControl-root .MuiInput-underline:after {
	border-bottom-color: var(--onsecondary) !important;
}
