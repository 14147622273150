html {
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: fixed;
}

body {
	height: 100%;
	width: 100%;
	overflow: auto;
	position: relative;

	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	--primary: #57c4e5;
	--secondary: #e7004a;
	--fill: #fff;
	--onprimary: #fff;
	--onsecondary: #fff;
	--onfill: var(--primary);
}

::-webkit-scrollbar {
	width: 0.5rem;
}

::-webkit-scrollbar-thumb {
	background-color: var(--secondary);
	border-radius: 0.5rem;
}

@font-face {
	font-family: BMWGroupTN;
	src: url("./fonts/BMWGroupTN-Regular.ttf");
}
@font-face {
	font-family: BMWGroupTN;
	src: url("./fonts/BMWGroupTN-Bold.ttf");
	font-weight: bold;
}

@font-face {
	font-family: BMWGroupTNCondensed;
	src: url("./fonts/BMWGroupTNCondensed-Regular.ttf");
}
@font-face {
	font-family: BMWGroupTNCondensed;
	src: url("./fonts/BMWGroupTNCondensed-Bold.ttf");
	font-weight: bold;
}
@font-face {
	font-family: BMWTypeNext;
	src: url("./fonts/BMWTypeNext-Light.otf");
}
@font-face {
	font-family: BMWTypeNext;
	src: url("./fonts/BMWTypeNext-Bold.woff2");
	font-weight: bold;
}

@font-face {
	font-family: MINISerif;
	src: url("./fonts/MINISerif-Regular.ttf");
}
@font-face {
	font-family: MINISerif;
	src: url("./fonts/MINISerif-Bold.ttf");
	font-weight: bold;
}

@font-face {
	font-family: MINISerifHeadline;
	src: url("./fonts/MINISerif-Headline.ttf");
}

* {
	font-family: MINISerif, "Times New Roman", Times, serif, Arial,
		"Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif !important;

	background-repeat: no-repeat !important;
	flex-shrink: 0;
	box-sizing: border-box;
}

h4,
h2,
.MuiTypography-h4,
.MuiTypography-h2,
.MuiTypography-h4 *,
.MuiTypography-h2 * {
	font-family: MINISerif, "Times New Roman", Times, serif, Arial,
		"Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif !important;
}

.MuiTypography-paragraph {
	margin: 0;
	padding-bottom: 16px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#root {
	height: 100%;
}
