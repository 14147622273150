.fade-enter {
	opacity: 0;
}
.fade-exit {
	opacity: 1;
}
.fade-enter-active {
	opacity: 1;
}
.fade-exit-active {
	opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
	transition: opacity 250ms;
}

.longfade-enter {
	opacity: 0;
}
.longfade-exit {
	opacity: 1;
}
.longfade-enter-active {
	opacity: 1;
	transition: opacity 500ms 500ms;
}
.longfade-exit-active {
	opacity: 0;
	transition: opacity 500ms 0s;
}
